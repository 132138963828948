import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

const Cta = () => {
  return (
    <React.Fragment>
      <div className="section-sm bg-cta" id="contact">
        <div className="color-overlay"></div>
        <Container>
          {/* <Row className="justify-content-center">
            <Col lg={8}>
              <div className="heading-box text-white text-center">
                <h3 className="heading-title">Let's Get Started</h3>
                <p className="heading-desc text-white-50 mt-3">
                  Far far away, behind the word mountains, far from the
                  countries Vokalia and Consonantia, there suspendisse suscipit
                  sapien sit amet live the blind texts.
                </p>

                <div className="mt-4">
                  <Link to="#" className="btn btn-primary">
                    Get Started <i className="mdi mdi-arrow-right ps-1"></i>
                  </Link>
                </div>

                <p className="mt-4 mb-0">
                  Have a question? Give us a call 347-776-3316
                </p>
              </div>
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Cta;
