
import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { Axios } from "../config";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    Axios
      .post(`/contact`, formData)
      .then((response) => {
				console.log(response);
        alert("Mensaje enviado correctamente");
        setFormData({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
      })
      .catch((error) => {
        alert("Hubo un error al enviar el mensaje");
      });
  };

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="heading-box text-center">
                <h3 className="heading-title">
                  <span className="fw-normal">Contáctanos</span>{" "}
                </h3>
                <p className="heading-desc text-muted mt-3">
                  Nuestros expertos esperan por ti
                </p>
              </div>
            </Col>
          </Row>

          <Row className="mt-5 pt-4">
            <Col lg={8} className="offset-lg-1">
              <div className="custom-form mt-4">
                <form onSubmit={handleSubmit}>
                  <p id="error-msg"></p>
                  <div id="simple-msg"></div>
                  <Row>
                    <Col lg={6}>
                      <div className="form-group mt-2">
                        <input
                          name="name"
                          id="name"
                          type="text"
                          className="form-control"
                          placeholder="Nombre"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="form-group mt-2">
                        <input
                          name="email"
                          id="email"
                          type="email"
                          className="form-control"
                          placeholder="Correo electrónico"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <div className="form-group mt-2">
                        <input
													name="subject"
                          type="text"
                          className="form-control"
                          id="subject"
                          placeholder="Asunto"
                          value={formData.subject}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <div className="form-group mt-2">
                        <textarea
                          name="message"
                          id="message"
                          rows={4}
                          className="form-control"
                          placeholder="Tu Mensaje"
                          value={formData.message}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col lg={12}>
                      <input
                        type="submit"
                        id="submit"
                        name="send"
                        className="submitBnt btn btn-rounded btn-primary"
                        value="Enviar Mensaje"
                      />
                    </Col>
                  </Row>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Contact;
