import React, { useState } from "react";
import {
  Col,
  Container,
  Row,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

//import images
import user1 from "../assets/images/users/img-1.jpg";
import user2 from "../assets/images/users/img-2.jpg";
import user3 from "../assets/images/users/img-3.jpg";
import client1 from "../assets/images/clients/img-1.png";
import client2 from "../assets/images/clients/img-2.png";
import client3 from "../assets/images/clients/img-3.png";
import client4 from "../assets/images/clients/img-4.png";
import client5 from "../assets/images/clients/img-5.png";

const items = [
  {
    src: user1,
    desc: "“I feel confident imposing change on myself. Its a lot more fun proging than looking back. Thats why I ultricies enim at malesuada creatures and the whole of quis consectetur nunc diam on tortor throw curve balls.”",
    name: "Brandon Carney",
    designation: "Designer",
  },
  {
    src: user2,
    desc: "“Our task must be to free ourselves by widening our circle of comsion to embrace all living creatures and the whole of quis consectetur nunc sit amet semper justo. scelerisque pretium nature and its beauty.”",
    name: "Jeremiah Eskew",
    designation: "Devloaper",
  },
  {
    src: user3,
    desc: "“Ive learned that people will forget what you said, people will forget what you did, but people will never aliquam in nunc quis tincidunt forget how you never forget how donec in efficitur lectus, them feel.”",
    name: "Zachary Tevis",
    designation: "Manager",
  },
];

const Clients = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex: number) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <div className="client-item">
          <Row>
            <Col lg={4}>
              <div className="mt-4">
                <div className="testi-img">
                  <img src={item.src} className="img-fluid" alt="" />
                </div>

                <div className="testi-icon bg-primary">
                  <i className="mdi mdi-format-quote-open text-white"></i>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="testi-box mt-4 rounded">
                <p className="testi-title text-muted mb-2">{item.desc}</p>
                <div className="row align-items-center">
                  <Col lg={6}>
                    <div className="mt-4">
                      <h5 className="f-18 mb-0">{item.name}</h5>
                      <p className="text-muted mb-0">- {item.designation}</p>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mt-4">
                      <ul className="list-inline text-end mb-0">
                        <li className="list-inline-item">
                          <i className="mdi mdi-star text-warning f-16"></i>
                        </li>
                        <li className="list-inline-item">
                          <i className="mdi mdi-star text-warning f-16"></i>
                        </li>
                        <li className="list-inline-item">
                          <i className="mdi mdi-star text-warning f-16"></i>
                        </li>
                        <li className="list-inline-item">
                          <i className="mdi mdi-star text-warning f-16"></i>
                        </li>
                        <li className="list-inline-item">
                          <i className="mdi mdi-star text-warning f-16"></i>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </CarouselItem>
    );
  });

  return (
    <React.Fragment>
      <section className="section bg-light" id="client">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="heading-box text-center">
                <h3 className="heading-title">
                  <span className="fw-normal">Nuestros</span>{" "}
                  Clientes
                </h3>
                <p className="heading-desc text-muted mt-3">
                </p>
              </div>
            </Col>
          </Row>

          <Row className="mt-5" style={{ display: "flex", justifyContent: "center"}}>
            <Col lg={2}>
              <div className="client-images mt-4">
                <img
                  src={client1}
                  alt="logo-img"
                  className="mx-auto img-fluid d-block"
                />
              </div>
            </Col>
            <Col lg={2}>
              <div className="client-images mt-4">
                <img
                  src={client2}
                  alt="logo-img"
                  className="mx-auto img-fluid d-block"
                />
              </div>
            </Col>
            <Col lg={2}>
              <div className="client-images mt-4">
                <img
                  src={client3}
                  alt="logo-img"
                  className="mx-auto img-fluid d-block"
                />
              </div>
            </Col>
            <Col lg={2}>
              <div className="client-images mt-4">
                <img
                  src={client4}
                  alt="logo-img"
                  className="mx-auto img-fluid d-block"
                />
              </div>
            </Col>
            <Col lg={2}>
              <div className="client-images mt-4">
                <img
                  src={client5}
                  alt="logo-img"
                  className="mx-auto img-fluid d-block"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Clients;
