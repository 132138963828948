import React from "react";
import { Col, Container, Row } from "reactstrap";
import CountUp from "react-countup";

const Counter = () => {
  return (
    <React.Fragment>
      <section className="section-sm bg-counter">
        <div className="color-overlay"></div>
        <Container>
          {/* <Row>
            <Col lg={3}>
              <div className="counter-box text-center mt-4 mt-lg-0 text-white">
                <div className="mt-4">
                  <i className="pe-7s-user display-4"></i>
                </div>
                <h1 className="counter_value mt-3">
                  <CountUp start={0} end={370} duration={6} />
                </h1>
                <p className="mb-0 mt-2 text-uppercase">Happy Clients</p>
              </div>
            </Col>

            <Col lg={3}>
              <div className="counter-box text-center mt-4 mt-lg-0 text-white">
                <div className="mt-4">
                  <i className="pe-7s-portfolio display-4"></i>
                </div>
                <h1 className="counter_value mt-3">
                  <CountUp start={0} end={685} duration={6} />
                </h1>
                <p className="mb-0 mt-2 text-uppercase">Projects Done</p>
              </div>
            </Col>

            <Col lg={3}>
              <div className="counter-box text-center mt-4 mt-lg-0 text-white">
                <div className="mt-4">
                  <i className="pe-7s-medal display-4"></i>
                </div>
                <h1 className="counter_value mt-3">
                  <CountUp start={0} end={125} duration={6} />
                </h1>
                <p className="mb-0 mt-2 text-uppercase">Awward Won</p>
              </div>
            </Col>

            <Col lg={3}>
              <div className="counter-box text-center mt-4 mt-lg-0 text-white">
                <div className="mt-4">
                  <i className="pe-7s-stopwatch display-4"></i>
                </div>
                <h1 className="mt-3">
                  <span className="counter_value">
                    <CountUp start={0} end={500} duration={6} />
                  </span>
                  k
                </h1>
                <p className="mb-0 mt-2 text-uppercase">Hours of Work</p>
              </div>
            </Col>
          </Row> */}
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Counter;
