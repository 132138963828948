import React, { useState } from "react";
import { Col, Container, Modal, Row } from "reactstrap";

const Section = () => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  return (
    <React.Fragment>
      <section className="bg-home-2 align-items-center d-flex" id="home">
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <div className="text-center">
                <h1 className="display-5 fw-normal">
                Servicios de Tecnologías de la{" "}
                  <span className="fw-bold">INFORMACION</span>
                </h1>

                <p className="home-desc text-muted mt-4">
                Somos una empresa orientada a entregar soluciones de 
                negocio apoyadas con tecnologías que entreguen valor 
                y crecimiento a nuestros clientes.
                </p>


              </div>
            </Col>
          </Row>
        </Container>

        <Modal
          isOpen={modal}
          toggle={toggle}
          className="bd-example-modal-lg"
          centered
          size="lg"
        >
          <button type="button" onClick={() => setModal(false)} className="close text-white"><span aria-hidden="true">×</span></button>
          <video id="VisaChipCardVideo" className="video-box" controls>
            <source
              src="https://www.w3schools.com/html/mov_bbb.mp4"
              type="video/mp4"
            />
          </video>
        </Modal>
      </section>
    </React.Fragment>
  );
};

export default Section;
