import React from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

//import images
import logoDark from "../assets/images/Logo/Logo.png";

const Footer = () => {
  return (
    <React.Fragment>
      <section className="bg-footer bg-light">
        <Container>
          <Row className="text-center" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Col md={6}>
              <div className="text-sm-start mt-3 mt-sm-0">
                <img src={logoDark} alt="" height="120" />
              </div>
            </Col>
            <Col md={6}>
              <div className="text-sm-end mt-3 mt-sm-0">
                <p className="text-muted mb-0">
                  {new Date().getFullYear()} © Copyright 2009 - 2024{" "}
                  <Link
                    to="//efectis.cl/"
                    className="text-muted fw-bold"
                    target="_blank"
                  >
                    EFECTIS
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Footer;
