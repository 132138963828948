import React from "react";
import { Col, Container, Row } from "reactstrap";

//import images
import features1 from "../assets/images/features/img-1.png";
import features2 from "../assets/images/features/img-2.png";

const Features = () => {
  return (
    <React.Fragment>
      <section className="section bg-light" id="features">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="heading-box text-center">
                <h3 className="heading-title">
                  <span className="fw-normal">Las características </span> que presentamos
                </h3>
                <p className="heading-desc text-muted mt-3">
                Creamos pensamiento digital, gráfico y dimensional para crear experiencias de 
                marca líderes en su categoría que tengan significado y agreguen valor para nuestros clientes.
                </p>
              </div>
            </Col>
          </Row>

          <Row className="mt-5 pt-4 align-items-center">
            <Col lg={5}>
              <div className="mt-4">
                <div className="mt-4">
                  <h3>
                    <span className="fw-normal">Estamos en constante búsqueda de nuevas tecnologías </span>
                    
                  </h3>
                </div>

                <div className="mt-4 pt-3">
                  <div className="d-flex">
                    <div className="features-count">
                      <h5 className="f-14 text-primary">01</h5>
                    </div>

                    <div className="flex-1 ps-3">
                      <h5 className="f-18 mt-1">Tecnologías Agiles</h5>
                      <p className="text-muted mt-2">
                      Entregamos soluciones rápidas con modelos de escabilidad que permiten una mejor interacción en las tecnologías utilizadas
                      </p>
                    </div>
                  </div>

                  <div className="d-flex mt-3">
                    <div className="features-count">
                      <h5 className="f-14 text-primary">02</h5>
                    </div>

                    <div className="flex-1 ps-3">
                      <h5 className="f-18 mt-1">Orientados al Negocio</h5>
                      <p className="text-muted mt-2 mb-0">
                      Estamos en constante búsqueda de mejores prácticas para incluir en nuestros desarrollos.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} className="offset-lg-1">
              <div className="mt-4 p-5">
                <img src={features1} className="img-fluid" alt="" />
              </div>
            </Col>
          </Row>

          <Row className="mt-5 pt-4 align-items-center">
            <Col lg={6}>
              <div className="mt-4 p-5">
                <img src={features2} className="img-fluid" alt="" />
              </div>
            </Col>

            <Col lg={5} className="offset-lg-1">
              <div className="mt-4">
                <h3>
                  <span className="fw-normal">Soluciones creativas </span>{" "}
                
                </h3>
              </div>

              <div className="mt-4 pt-3">
                <div className="d-flex">
                  <div className="features-icon">
                    <i className="pe-7s-display2 text-primary"></i>
                  </div>
                  <div className="flex-1 ps-3">
                    <h5 className="f-18 mt-1">
                    Diseñamos Soluciones acorde al negocio
                    </h5>
                    <p className="text-muted mt-2">
                    Nuestro interés es acercar al máximo la tecnología al negocio buscando optimizar procesos y automatizaciones                     </p>
                  </div>
                </div>

                <div className="d-flex mt-3">
                  <div className="features-icon">
                    <i className="pe-7s-date text-primary"></i>
                  </div>
                  <div className="flex-1 ps-3">
                    <h5 className="f-18 mt-1">Nuestra Organization.</h5>
                    <p className="text-muted mt-2">
                    Contamos con una diversidad de profesionales funcionales técnicos de alto nivel que nos permiten entregar un servicio de calidad 
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Features;
