import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

const Services = () => {
  return (
    <React.Fragment>
      <section className="section" id="services">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="heading-box text-center">
                <h3 className="heading-title fw-normal">
                Para lograr el resultado contamos con colaboradores - 
                  <span className="fw-bold">
                   que tienen una amplia experiencia y compromiso en la implementación de sistemas estándar a medida y desarrollos específicos, 
                  utilizando las herramientas especificas para obtener el objetivo,
                  </span>{" "}
                  nuestra mayor responsabilidad para con nuestros clientes es entregar la satisfacción plena de nuestros soluciones.
                </h3>
                <p className="heading-desc text-muted mt-4">
                El éxito de Efectis TI, se basa fundamentalmente en la filosofía de trabajo en equipo y la cooperación mutua con el uso 
                de la mejor tecnología para lograr la solución optima.
                </p>
              </div>
            </Col>
          </Row>

          <Row className="mt-5 pt-4">
            <Col lg={4}>
              <div className="services-box text-center hover-effect mt-4">
                <div className="services-icon">
                  <i className="pe-7s-network"></i>
                </div>
                <h5 className="mt-4 pt-1">Inteligencia de Negocio</h5>
                <p className="text-muted mt-3 mb-0">
                Análisis, Diseño e Implementación en soluciones de negocio con tecnología Olap.
                </p>
                {/* <div className="mt-4">
                  <Link to="#" className="text-primary">
                    Read More <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div> */}
              </div>
            </Col>

            <Col lg={4}>
              <div className="services-box text-center hover-effect mt-4">
                <div className="services-icon">
                  <i className="pe-7s-server"></i>
                </div>
                <h5 className="mt-4 pt-1">Levantamiento de procesos</h5>
                <p className="text-muted mt-3 mb-0">
                Identificación de procesos actuales, mejoramiento desde perspectiva funcional y operacional, 
                apoyo en búsqueda del mejor Software del mercado con mayor adherencia a los procesos tanto funcionales como operacionales.
                </p>
                {/* <div className="mt-4">
                  <Link to="#" className="text-primary">
                    Read More <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div> */}
              </div>
            </Col>

            <Col lg={4}>
              <div className="services-box text-center hover-effect mt-4">
                <div className="services-icon">
                  <i className="pe-7s-add-user"></i>
                </div>
                <h5 className="mt-4 pt-1">Implementación ERP</h5>
                <p className="text-muted mt-3 mb-0">
                Consultoría en Sistema ERP, Ventas, Compras, Distribución, Producción, Cuentas por pagar, Cuentas por Cobrar, Inventario, Existencias, RRHH.
                </p>
             
               {/* <div className="mt-4">
                  <Link to="#" className="text-primary">
                    Read More <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                  </div>
                   */}
              </div>
            </Col>

            <Col lg={4}>
              <div className="services-box text-center hover-effect mt-4">
                <div className="services-icon">
                  <i className="pe-7s-add-user"></i>
                </div>
                <h5 className="mt-4 pt-1">Integradores</h5>
                <p className="text-muted mt-3 mb-0">
                Buscamos la mejor alternativa de mercado para entregar la solución a medida.
                </p>
             
               {/* <div className="mt-4">
                  <Link to="#" className="text-primary">
                    Read More <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                  </div>
                   */}
              </div>
            </Col>

            <Col lg={4}>
              <div className="services-box text-center hover-effect mt-4">
                <div className="services-icon">
                  <i className="pe-7s-add-user"></i>
                </div>
                <h5 className="mt-4 pt-1">Implementación de Planes de Contingencia</h5>
                <p className="text-muted mt-3 mb-0">
                Levantamiento e implementación de planes de contingencia.
                </p>
             
               {/* <div className="mt-4">
                  <Link to="#" className="text-primary">
                    Read More <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                  </div>
                   */}
              </div>
            </Col>

            <Col lg={4}>
              <div className="services-box text-center hover-effect mt-4">
                <div className="services-icon">
                  <i className="pe-7s-add-user"></i>
                </div>
                <h5 className="mt-4 pt-1">Desarrollo de aplicaciones</h5>
                <p className="text-muted mt-3 mb-0">
                Soluciones de negocio a medida.
                </p>
             
               {/* <div className="mt-4">
                  <Link to="#" className="text-primary">
                    Read More <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                  </div>
                   */}
              </div>
            </Col>

            <Col lg={4}>
              <div className="services-box text-center hover-effect mt-4">
                <div className="services-icon">
                  <i className="pe-7s-add-user"></i>
                </div>
                <h5 className="mt-4 pt-1">Tecnologías</h5>
                <p className="text-muted mt-3 mb-0">
                .ASP.NET, PHP, HTML, J2EE (Java), SQL, Visual Basic, VB para Aplicaciones, SqlServer, Oracle, MySql, OLAP, Progress ABL, Progress WebSpeed, OpenEdge de Progress, QAD, entre otras.
                </p>
             
               {/* <div className="mt-4">
                  <Link to="#" className="text-primary">
                    Read More <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                  </div>
                   */}
              </div>
            </Col>

            <Col lg={4}>
              <div className="services-box text-center hover-effect mt-4">
                <div className="services-icon">
                  <i className="pe-7s-add-user"></i>
                </div>
                <h5 className="mt-4 pt-1">Solución a Problemáticas TI</h5>
                <p className="text-muted mt-3 mb-0">
                Análisis, Diseño y Alternativas de Solución.
                </p>
             
               {/* <div className="mt-4">
                  <Link to="#" className="text-primary">
                    Read More <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                  </div>
                   */}
              </div>
            </Col>

            <Col lg={4}>
              <div className="services-box text-center hover-effect mt-4">
                <div className="services-icon">
                  <i className="pe-7s-add-user"></i>
                </div>
                <h5 className="mt-4 pt-1">Desarrollo de Aplicaciones Móviles</h5>
                <p className="text-muted mt-3 mb-0">
                Para todo tipo de Sistemas Operativos Móviles.
                </p>
             
               {/* <div className="mt-4">
                  <Link to="#" className="text-primary">
                    Read More <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                  </div>
                   */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Services;
