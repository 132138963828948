import React from "react";

//import Custom Style scss
import "./assets/scss/themes.scss";

//import Icons
import "./assets/css/pe-icon-7-stroke.css";
import "./assets/css/materialdesignicons.min.css";
import Layout from "./Layout";
import Layout2 from "./pages/Layout2/Layout2";

function App() {
  return (
    <React.Fragment>
      <Layout>
				<Layout2 />
			</Layout>
    </React.Fragment>
  );
}

export default App;
