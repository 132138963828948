import axios from "axios";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    window.location.hostname === "[::1]"
);

const API_URL = isLocalhost
  ? "http://localhost:5000/api"
  : "https://api.efectis.cl/api";

export const Axios = axios.create({
  // withCredentials: true,
  baseURL: API_URL
});
